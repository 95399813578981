import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  ModuleAdd,
  createOrganizationModule,
  createOrganizationSetting,
  deleteOrganizationModule,
  getOrganizationList,
  getOrganizationModuleList,
  getOrganizationSettingById,
  updateOrganizationModule,
  updateOrganizationSetting,
} from '../../services/OrganizationService'
import {getOrganizationById} from '../../services/OrganizationService'
import {deleteOrganization} from '../../services/OrganizationService'
import {createOrganization} from '../../services/OrganizationService'
import {updateOrganization} from '../../services/OrganizationService'

interface GetOrganizationListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown:number
}

interface OrganizationState {
  OrganizationList: any
  organizationData: any
  createOrganizationData: any
  updateOrganizationData: any
  organizationSettingData: any
  organizationSettingUpdate: any
  moduleAdded: any
  moduleUpdated: any
  moduleListbyOrganization: any
  counter: any
}

const initialState: OrganizationState = {
  OrganizationList: null,
  organizationData: null,
  createOrganizationData: null,
  updateOrganizationData: null,
  organizationSettingData: null,
  moduleAdded: null,
  moduleUpdated: null,
  moduleListbyOrganization: null,
  organizationSettingUpdate: null,
  counter: [{ksks: 'ksksk'}],
}

export const createOrganizationApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createOrganization(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationListApi = createAsyncThunk(
  'OrganizationList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetOrganizationListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getOrganizationList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getOrganizationByIdApi = createAsyncThunk(
  'OrganizationById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getOrganizationById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateOrganizationApi = createAsyncThunk(
  'updateOrganization/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateOrganization(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteOrganizationApi = createAsyncThunk(
  'Organization/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteOrganization(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const AddedOrganizationModuleApi = createAsyncThunk(
  'OrganizationModule/api/create',
  async (payload: ModuleAdd, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await createOrganizationModule(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const UpdateOrganizationModuleApi = createAsyncThunk(
  'OrganizationModule/api/update',
  async ({id, payload}: {id: string; payload: any}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await updateOrganizationModule(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const GetmoduleListbyOrganizationId = createAsyncThunk(
  'OrganizationModule/api/list',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getOrganizationModuleList(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const GetOrganizationSettingByIdApi = createAsyncThunk(
  'OrganizationSetting/api/data',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getOrganizationSettingById(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const createOrganizationSettingApi = createAsyncThunk(
  'OrganizationSetting/api/create',
  async (payload: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await createOrganizationSetting(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const UpdateOrganizationSettingByIdApi = createAsyncThunk(
  'OrganizationSetting/api/update',
  async ({id, payload}: {id: string; payload: any}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await updateOrganizationSetting(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const DeleteModuleFromOrganization = createAsyncThunk(
  'OrganizationModule/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteOrganizationModule(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// createOrganizationModule

const OrganizationSlice = createSlice({
  name: 'Organization',
  initialState,
  reducers: {
    addSidebarOption: (state: any, action: any) => {
      // if(state.organizationSettingData.sidebar)
      // state.organizationSettingData.settings_json=null
      console.log(state.organizationSettingData.settings_json)
      // state.organizationSettingData.settings_json.sidebar.push(action.payload.formdata)
      // console.log(state.organizationSettingData)
      return state
    },
    addSidebarChildOption: (state: any, action: any) => {
      console.log(state, action)
      // console.log(action.payload)
      // state.list = action?.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationListApi.fulfilled, (state, action) => {
        state.OrganizationList = action.payload
      })
      .addCase(getOrganizationByIdApi.fulfilled, (state, action) => {
        state.organizationData = action.payload
      })
      .addCase(createOrganizationApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createOrganizationData = action?.payload
      })
      .addCase(updateOrganizationApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateOrganizationData = action?.payload
      })
      .addCase(AddedOrganizationModuleApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.moduleAdded = action?.payload
      })
      .addCase(GetmoduleListbyOrganizationId.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.moduleListbyOrganization = action?.payload
      })
      .addCase(UpdateOrganizationModuleApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.moduleUpdated = action?.payload
      })
      .addCase(GetOrganizationSettingByIdApi.fulfilled, (state, action) => {
        state.organizationSettingData = action?.payload
      })
      .addCase(UpdateOrganizationSettingByIdApi.fulfilled, (state, action) => {
        state.organizationSettingUpdate = action?.payload
      })
  },
})

export const {addSidebarOption, addSidebarChildOption} = OrganizationSlice.actions
export default OrganizationSlice
