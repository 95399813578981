/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { currentUser, logIn } from '../../../services/AuthService'
import { googleLogin } from '../../../services/AuthService'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
// import {GoogleLogin} from 'react-google-login'
import { useGoogleLogin } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { getUserByToken } from '../core/_requests'
import { useAuth } from '../core/Auth'


const loginSchema = Yup.object().shape({
  
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols') 
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  // const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      const FormData = {
        email: values.email,
        password: values.password,
      }

      try {
        const data = await logIn(FormData)
        // setLoginStatus(data?.status)

        const auth = data.data.data.token
        const modules = JSON.stringify(data.data.data.modules)
        localStorage.setItem('loginToken', auth)
        localStorage.setItem('modules',modules)

        const response = await currentUser()
        localStorage.setItem('currentUser', JSON.stringify(response?.data?.data))

        toast.success('Login Successfully')
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)
        if (data?.status === 200) {
          navigate('/dashboard/fleet-dashboard')
          window.location.reload()
        }
      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        toast.error('Login failed. Please check your credentials.')
      }
    },
  })



  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >

      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-12'>
          {/* <a
          onClick={() => loginGoogle()}
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}

          <GoogleLogin
            onSuccess={async (credentialResponse: any) => {
              // const Response = jwtDecode(credentialResponse?.credential)
              const Response = credentialResponse?.credential
              console.log(credentialResponse?.credential)
              if (credentialResponse?.credential) {
                const FormData = {
                  idtoken: Response,
                }
                try {
                  const data = await googleLogin(FormData)
                  const auth = data.data.data.data.token
                  localStorage.setItem('loginToken', auth)
                  toast.success('Login Successfully')

                  if (data?.status === 200) {
                    navigate('/dashboard')
                    window.location.reload()
                  }
                } catch (error) {
                  console.error(error)
                }
              }
            }}
            onError={() => {
              console.log('Login Failed')
            }}
            logo_alignment='center'
          />
        </div>

        {/* <div className='col-md-6'>
       
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
      
        </div> */}
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      {formik.status ? (
        <div className='mb-lg-7 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 '>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
