import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createVehicleTyre, getTyresByVehicleId, removeUsedTyre } from "../../services/VehicleTyreService"
import { getVehicleTyreList } from "../../services/VehicleTyreService"
import { updateVehicleTyre } from "../../services/VehicleTyreService"
import { deleteVehicleTyre } from "../../services/VehicleTyreService"
import { getVehicleTyreById } from "../../services/VehicleTyreService"



interface GetVehicleTyreListParams {
    pageNumber: number
    pageSize: number
    filterkey: any
  }
  
  interface VehicleTyreState {
    VehicleTyreList: any
    VehicleTyreData: any
    createVehicleTyreData: any
    updateVehicleTyreData: any
    deleteVehicleTyreData: any
    UsedTyreList:any
    removedUsedTyre:any
  }
  
  const initialState: VehicleTyreState = {
    VehicleTyreList: null,
    VehicleTyreData: null,
    createVehicleTyreData: null,
    updateVehicleTyreData: null,
    deleteVehicleTyreData: null,
    UsedTyreList:null,
    removedUsedTyre:null
  }
  
  
export const createVehicleTyreApi = createAsyncThunk(
    'VehicleTyreList/api/put',
  
    async ({payload}: {payload: any}, {rejectWithValue}) => {
      try {
        const result = await createVehicleTyre(payload)
        return result
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )

  export const getVehicleTyreListApi = createAsyncThunk(
    'VehicleTyreList/api/get',
    async (
      {pageNumber, pageSize, filterkey}: GetVehicleTyreListParams,
      {rejectWithValue, fulfillWithValue}
    ) => {
      try {
        const result = await getVehicleTyreList(pageNumber, pageSize, filterkey)
        return result.data
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )

  export const getVehicleTyreByIdApi = createAsyncThunk(
    'VehicleTyreById/api/get',
    async (id: any, {rejectWithValue, fulfillWithValue}) => {
      try {
        const result = await getVehicleTyreById(id)
        return result.data
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )

  export const updateVehicleTyreApi = createAsyncThunk(
    'updateVehicleTyre/api/put',
  
    async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
      try {
        const result = await updateVehicleTyre(id, payload)
        return result
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )

  export const deleteVehicleTyreApi = createAsyncThunk(
    'VehicleTyre/api/delete',
    async (id: any, {rejectWithValue, fulfillWithValue}) => {
      try {
        const result = await deleteVehicleTyre(id)
        return result.data
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )


  export const getTyresByVehicleIdApi = createAsyncThunk(
    'Tyre/api/list',
    async (id: any, {rejectWithValue, fulfillWithValue}) => {
      try {
        const result = await getTyresByVehicleId(id)
        return result
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )


  export const removeUsedTyreApi = createAsyncThunk(
    'removeTyre/api/list',
    async ({id,payload}:{id:any,payload:any}, {rejectWithValue, fulfillWithValue}) => {
      try {
        const result = await removeUsedTyre(id,payload)
        return result
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )




  export const VehicleTyreSlice: any = createSlice({
    name: 'VehicleTyre',
    initialState,
    reducers: {},


    extraReducers: (builder) => {
      builder
        .addCase(createVehicleTyreApi.fulfilled, (state, action) => {
          state.createVehicleTyreData = action?.payload
        })
        .addCase(getVehicleTyreListApi.fulfilled, (state, action) => {
          console.log(action?.payload?.data)
          state.VehicleTyreList = action?.payload
        })  
        .addCase(getVehicleTyreByIdApi.fulfilled, (state, action) => {
          state.VehicleTyreData = action.payload
        })
        .addCase(updateVehicleTyreApi.fulfilled, (state, action) => {
          state.updateVehicleTyreData = action.payload
        })
        .addCase(deleteVehicleTyreApi.fulfilled, (state, action) => {
          state.deleteVehicleTyreData = action.payload
        })
        .addCase(getTyresByVehicleIdApi.fulfilled, (state, action) => {
          state.UsedTyreList = action.payload
        })
        .addCase(removeUsedTyreApi.fulfilled, (state, action) => {
          state.removedUsedTyre = action.payload
        })
    },
  })

  
  export default VehicleTyreSlice.reducer