import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {getAvailableTyre, getTyreList} from '../../services/TyreService'
import {getTyreById} from '../../services/TyreService'
import {deleteTyre} from '../../services/TyreService'
import {createTyre} from '../../services/TyreService'
import {updateTyre} from '../../services/TyreService'
import {getAllTyreList} from '../../services/TyreService'

interface GetTyreListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface TyreState {
  TyreList: any
  TyreData: any
  createTyreData: any
  updateTyreData: any
  AllTyreList: any
  AvailableTyre: any
}

const initialState: TyreState = {
  TyreList: null,
  TyreData: null,
  createTyreData: null,
  updateTyreData: null,
  AllTyreList: null,
  AvailableTyre: null

}

export const createTyreApi = createAsyncThunk(
  'TyreList/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createTyre(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllTyreListApi = createAsyncThunk(
  'AllTyreList/api/get',
  async ({}: 
    {}, 
    {rejectWithValue}
  ) => {
    try {
      const result = await getAllTyreList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getTyreListApi = createAsyncThunk(
  'TyreList/api/get',
  async (
    {pageNumber, pageSize, filterkey,}: GetTyreListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getTyreList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getAvailableTyreListApi = createAsyncThunk(
  'AvailableTyreList/api/get',
  async (_,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getAvailableTyre()
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getTyreByIdApi = createAsyncThunk(
  'TyreById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getTyreById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateTyreApi = createAsyncThunk(
  'updateTyre/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateTyre(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteTyreApi = createAsyncThunk(
  'Tyre/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteTyre(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const TyreSlice: any = createSlice({
  name: 'Tyre',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTyreListApi.fulfilled, (state, action) => {
        state.TyreList = action.payload
      })

      .addCase(getAllTyreListApi.fulfilled, (state, action) => {
        state.AllTyreList = action.payload
      })

      .addCase(getTyreByIdApi.fulfilled, (state, action) => {
        state.TyreData = action.payload
      })
      .addCase(createTyreApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createTyreData = action?.payload
      })
      .addCase(updateTyreApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateTyreData = action?.payload
      })
      .addCase(getAvailableTyreListApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.AvailableTyre = action?.payload
      })

      
  },
})
export default TyreSlice.reducer
