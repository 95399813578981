import instance from './instance'

export const createVehicleTyre = async (payload: any) =>{
  return await instance.post(`modules-fleet/vehicletyre`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
    if (err.response) {
      return err.response
    }
  }
  )
}


//-------------------- VehicleTyre ---------------------

export const getVehicleTyreList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`modules-fleet/vehicletyre?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateVehicleTyre = async (id: any, payload: any) =>{
  return await instance.post(`modules-fleet/vehicletyre/${id}`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
  
    if (err.response) {
      return err.response
    }
  }
  )
}



export const deleteVehicleTyre = (id: any) => instance.delete(`modules-fleet/vehicletyre/${id}`)

export const getVehicleTyreById = (id: number) => instance.get(`modules-fleet/vehicletyre/${id}`)


export const getTyresByVehicleId = (id: number) => instance.get(`modules-fleet/tyres-by-vehicle/${id}`)

export const removeUsedTyre = (id:any,payload:any) => instance.post(`modules-fleet/removetyre/${id}`,payload)

