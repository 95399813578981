import instance from './instance'

export const createVehicle = async (payload: any) => await instance.post('modules-fleet/vehicle', payload)

//-------------------- Vehicle ---------------------

export const getVehicleList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules-fleet/vehicle?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateVehicle= async (id: any, payload: any) =>await instance.post(`modules-fleet/vehicle/${id}`, payload)

export const deleteVehicle = (id: any) => instance.delete(`modules-fleet/vehicle/${id}`)

export const getVehicleById = (id: number) => instance.get(`modules-fleet/vehicle/${id}`)