import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {getTyreVendorList} from '../../services/TyreVendorService'
import {getTyreVendorById} from '../../services/TyreVendorService'
import {deleteTyreVendor} from '../../services/TyreVendorService'
import {createTyreVendor} from '../../services/TyreVendorService'
import {updateTyreVendor} from '../../services/TyreVendorService'
import {getAllTyreVendorList} from '../../services/TyreVendorService'

interface GetTyreVendorListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface TyreVendorState {
  TyreVendorList: any
  TyreVendorData: any
  createTyreVendorData: any
  updateTyreVendorData: any
  AllTyreVendorList: any

}

const initialState: TyreVendorState = {
  TyreVendorList: null,
  TyreVendorData: null,
  createTyreVendorData: null,
  updateTyreVendorData: null,
  AllTyreVendorList: null,

}

export const createTyreVendorApi = createAsyncThunk(
  'TyreVendorList/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createTyreVendor(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllTyreVendorListApi = createAsyncThunk(
  'AllTyreVendorList/api/get',
  async ({}:  {}, {rejectWithValue}
  ) => {
    try {
      const result = await getAllTyreVendorList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getTyreVendorListApi = createAsyncThunk(
  'TyreVendorList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetTyreVendorListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getTyreVendorList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getTyreVendorByIdApi = createAsyncThunk(
  'TyreVendorById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getTyreVendorById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateTyreVendorApi = createAsyncThunk(
  'updateTyreVendor/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateTyreVendor(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteTyreVendorApi = createAsyncThunk(
  'TyreVendor/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteTyreVendor(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const TyreVendorSlice: any = createSlice({
  name: 'TyreVendor',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTyreVendorListApi.fulfilled, (state, action) => {
        state.TyreVendorList = action.payload
      })
      .addCase(getAllTyreVendorListApi.fulfilled, (state, action) => {
        state.AllTyreVendorList = action.payload
      })
      .addCase(getTyreVendorByIdApi.fulfilled, (state, action) => {
        state.TyreVendorData = action.payload
      })
      .addCase(createTyreVendorApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createTyreVendorData = action?.payload
      })
      .addCase(updateTyreVendorApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateTyreVendorData = action?.payload
      })
  },
})
export default TyreVendorSlice.reducer
