import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {getClientList} from '../../services/ClientService'
import {getClientById} from '../../services/ClientService'
import {deleteClient} from '../../services/ClientService'
import {createClient} from '../../services/ClientService'
import {updateClient} from '../../services/ClientService'

interface GetClientListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown:number
}

interface ClientState {
  ClientList: any
  ClientData: any
  createClientData: any
  updateClientData: any

}

const initialState: ClientState = {
  ClientList: null,
  ClientData: null,
  createClientData: null,
  updateClientData: null,

}

export const createClientApi = createAsyncThunk(
  'ClientList/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createClient(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getClientListApi = createAsyncThunk(
  'ClientList/api/get',
  async (
    {pageNumber, pageSize, filterkey,isDropdown}: GetClientListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getClientList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getClientByIdApi = createAsyncThunk(
  'ClientById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getClientById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateClientApi = createAsyncThunk(
  'updateClient/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateClient(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteClientApi = createAsyncThunk(
  'Client/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteClient(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const ClientSlice: any = createSlice({
  name: 'Client',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getClientListApi.fulfilled, (state, action) => {
        state.ClientList = action.payload
      })
      .addCase(getClientByIdApi.fulfilled, (state, action) => {
        state.ClientData = action.payload
      })
      .addCase(createClientApi.fulfilled, (state, action) => {
        state.createClientData = action?.payload
      })
      .addCase(updateClientApi.fulfilled, (state, action) => {
        state.updateClientData = action?.payload
      })
  },
})


export default ClientSlice.reducer
