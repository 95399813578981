import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {getVehicleList} from '../../services/VehicleService'
import {getVehicleById} from '../../services/VehicleService'
import {deleteVehicle} from '../../services/VehicleService'
import {createVehicle} from '../../services/VehicleService'
import {updateVehicle} from '../../services/VehicleService'

interface GetVehicleListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown:number
}

interface VehicleState {
  VehicleList: any
  VehicleData: any
  createVehicleData: any
  updateVehicleData: any

}

const initialState: VehicleState = {
  VehicleList: null,
  VehicleData: null,
  createVehicleData: null,
  updateVehicleData: null,

}

export const createVehicleApi = createAsyncThunk(
  'VehicleList/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createVehicle(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getVehicleListApi = createAsyncThunk(
  'VehicleList/api/get',
  async (
    {pageNumber, pageSize, filterkey,isDropdown}: GetVehicleListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getVehicleList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getVehicleByIdApi = createAsyncThunk(
  'VehicleById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getVehicleById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateVehicleApi = createAsyncThunk(
  'updateVehicle/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateVehicle(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteVehicleApi = createAsyncThunk(
  'Vehicle/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteVehicle(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const VehicleSlice: any = createSlice({
  name: 'Vehicle',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getVehicleListApi.fulfilled, (state, action) => {
        state.VehicleList = action.payload
      })
      .addCase(getVehicleByIdApi.fulfilled, (state, action) => {
        state.VehicleData = action.payload
      })
      .addCase(createVehicleApi.fulfilled, (state, action) => {
        state.createVehicleData = action?.payload
      })
      .addCase(updateVehicleApi.fulfilled, (state, action) => {
        state.updateVehicleData = action?.payload
      })
  },
})


export default VehicleSlice.reducer
