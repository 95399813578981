import instance from './instance'

export const createDriver = async (payload: any) => { 
  return await instance.post('modules-fleet/driver', payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

//-------------------- Driver ---------------------

export const getAllDriverList = () =>
  instance.get(`modules-fleet/driver`)

export const getDriverList = (page: number, pageSize: number, filterkey: any,isDropDown:any) =>
  instance.get(`modules-fleet/driver?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropDown}`)

export const updateDriver = async (id: any, payload: any) =>{
  return await instance.post(`modules-fleet/driver/${id}`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
  
    if (err.response) {
      console.log(err.response.data)
      return err.response
    }
  }
  )

}

export const deleteDriver = (id: any) => instance.delete(`modules-fleet/driver/${id}`)

export const getDriverById = (id: number) => instance.get(`modules-fleet/driver/${id}`)
