import instance from './instance'

export const createClient = async (payload: any) => await instance.post('modules-fleet/client', payload)

//-------------------- client ---------------------

export const getClientList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules-fleet/client?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateClient= async (id: any, payload: any) =>await instance.post(`modules-fleet/client/${id}`, payload)

export const deleteClient = (id: any) => instance.delete(`modules-fleet/client/${id}`)

export const getClientById = (id: number) => instance.get(`modules-fleet/client/${id}`)