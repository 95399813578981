import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {AllTripList, getTripList} from '../../services/TripService'
import {getTripById} from '../../services/TripService'
import {deleteTrip} from '../../services/TripService'
import {createTrip} from '../../services/TripService'
import {updateTrip} from '../../services/TripService'
import { actions } from 'react-table'

interface GetTripListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown:number
}

interface TripState {
  allTripList:any
  tripList: any
  tripData: any
  createTripData: any
  updateTripData: any

}

const initialState: TripState = {
  allTripList:null,
  tripList: null,
  tripData: null,
  createTripData: null,
  updateTripData: null,

}

export const createTripApi = createAsyncThunk(
  'tripList/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createTrip(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getTripListApi = createAsyncThunk(
  'tripList/api/get',
  async (
    {pageNumber, pageSize, filterkey,isDropdown}: GetTripListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getTripList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllTripListApi = createAsyncThunk(
  'alltripList/api/get',
  async (
    {pageNumber, pageSize, filterkey,isDropdown}: GetTripListParams
    ,{rejectWithValue}) => {
    try {
      console.log("tyre list api call")
      const result = await AllTripList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getTripByIdApi = createAsyncThunk(
  'TripById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getTripById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateTripApi = createAsyncThunk(
  'updateTrip/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateTrip(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteTripApi = createAsyncThunk(
  'trip/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteTrip(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const TripSlice: any = createSlice({
  name: 'Trip',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTripListApi.fulfilled, (state, action) => {
        state.tripList = action.payload
      })
      .addCase(getTripByIdApi.fulfilled, (state, action) => {
        state.tripData = action.payload
      })
      .addCase(createTripApi.fulfilled, (state, action) => {
        // console.log(action?.payload?.data)
        state.createTripData = action?.payload
      })
      .addCase(updateTripApi.fulfilled, (state, action) => {
        // console.log(action?.payload)
        state.updateTripData = action?.payload
      })
      .addCase(getAllTripListApi.fulfilled, (state,action)=>{
        state.allTripList= action?.payload
      })
  },
})
export default TripSlice.reducer
