import instance from './instance'

export const createProgram = async (payload: any) => { 
  return await instance.post('modules-fleet/program', payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

//-------------------- Program ---------------------

export const getAllProgramList = () =>
  instance.get(`modules-fleet/program`)

export const getProgramList = (page: number, pageSize: number, filterkey: any, isDropdown:any) =>
  instance.get(`modules-fleet/program?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateProgram = async (id: any, payload: any) =>{
  return await instance.post(`modules-fleet/program/${id}`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
  
    if (err.response) {
      console.log(err.response.data)
      return err.response
    }
  }
  )

}

export const deleteProgram = (id: any) => instance.delete(`modules-fleet/program/${id}`)

export const getProgramById = (id: number) => instance.get(`modules-fleet/program/${id}`)
