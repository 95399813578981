import instance from './instance'

export const createTyre = async (payload: any) => { 
  return await instance.post('modules-fleet/tyre', payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

//-------------------- vehicle ---------------------

export const getAllTyreList = () =>
  instance.get(`modules-fleet/tyre`)



export const getTyreList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`modules-fleet/tyre?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateTyre = async (id: any, payload: any) =>{
  return await instance.post(`modules-fleet/tyre/${id}`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
  
    if (err.response) {
      console.log(err.response.data)
      return err.response
    }
  }
  )

}

export const deleteTyre = (id: any) => instance.delete(`modules-fleet/tyre/${id}`)

export const getTyreById = (id: number) => instance.get(`modules-fleet/tyre/${id}`)

export const getAvailableTyre = () => instance.get(`modules-fleet/available-tyres`)

