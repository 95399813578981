import instance from './instance'

export const createTyreVendor = async (payload: any) => { 
  return await instance.post('modules-fleet/vendor', payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

//-------------------- Vendor ---------------------

export const getAllTyreVendorList = () =>
  instance.get(`modules-fleet/vendor`)

export const getTyreVendorList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`modules-fleet/vendor?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateTyreVendor = async (id: any, payload: any) =>{
  return await instance.post(`modules-fleet/vendor/${id}`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
  
    if (err.response) {
      console.log(err.response.data)
      return err.response
    }
  }
  )

}

export const deleteTyreVendor = (id: any) => instance.delete(`modules-fleet/vendor/${id}`)

export const getTyreVendorById = (id: number) => instance.get(`modules-fleet/vendor/${id}`)

