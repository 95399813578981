import instance from './instance'

export const createTrip = async (payload: any) => await instance.post('modules-fleet/trip', payload)

//-------------------- Trip ---------------------

export const getTripList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules-fleet/trip?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateTrip = async (id: any, payload: any) =>await instance.post(`modules-fleet/trip/${id}`, payload)

export const deleteTrip = (id: any) => instance.delete(`modules-fleet/trip/${id}`)

export const getTripById = (id: number) => instance.get(`modules-fleet/trip/${id}`)

export const AllTripList = (page: number, pageSize: number, filterkey: any,isDropdown:any)=> instance.get(`modules-fleet/trips?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)