import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {createProgram} from '../../services/ProgramService'
import {getProgramList} from '../../services/ProgramService'
import {getProgramById} from '../../services/ProgramService'
import {updateProgram} from '../../services/ProgramService'
import { deleteProgram } from '../../services/ProgramService'
import { getAllProgramList } from '../../services/ProgramService'



interface GetProgramListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown : any
}

interface ProgramState {
  ProgramList: any
  ProgramData: any
  createProgramData: any
  updateProgramData: any
  AllProgramList: any
  // -------------------
}

const initialState: ProgramState = {
  ProgramList: null,
  ProgramData: null,
  createProgramData: null,
  updateProgramData: null,
  AllProgramList: null,
  // -------------------

}

export const createProgramApi = createAsyncThunk(
  'ProgramList/api/post',

  async (payload: any, {rejectWithValue}) => {
      const result = await createProgram(payload)
      if(result.data.success)
      return result.data
      else
      return rejectWithValue(result.data)
  }
)


export const getAllProgramListApi = createAsyncThunk(
  'AllProgramList/api/get',
  async ({}:  {}, 
    {rejectWithValue}
  ) => {
    try {
      const result = await getAllProgramList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



export const getProgramListApi = createAsyncThunk(
  'ProgramList/api/get',
  async (
    {pageNumber, pageSize, filterkey,isDropdown}: GetProgramListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getProgramList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getProgramByIdApi = createAsyncThunk(
  'ProgramById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getProgramById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateProgramApi = createAsyncThunk(
  'Program/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    
      const result = await updateProgram(id, payload)
      if(result.data.success)
      return result.data
      else
      return rejectWithValue(result.data)
  }
)

export const deleteProgramApi = createAsyncThunk(
  'Program/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteProgram(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)




export const ProgramSlice: any = createSlice({
  name: 'Program',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getProgramListApi.fulfilled, (state, action) => {
        state.ProgramList = action.payload
      })
      .addCase(getAllProgramListApi.fulfilled, (state, action) => {
        state.AllProgramList = action.payload
      })

      .addCase(getProgramByIdApi.fulfilled, (state, action) => {
        state.ProgramData = action.payload
      })
      .addCase(createProgramApi.fulfilled, (state, action) => {
        state.createProgramData = action?.payload
      })
      .addCase(updateProgramApi.fulfilled, (state, action) => {
        state.updateProgramData = action?.payload
      })
    
  },
})
export default ProgramSlice.reducer
