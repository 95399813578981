import {configureStore} from '@reduxjs/toolkit'
import OrganizationSlice from './slices/OrganizationSlices'
import OrganizationLocationSlice from './slices/OrganizationLocationSlices'
import UserSlice from './slices/UserSlices'
import RoleSlice from './slices/RoleSlices'
import GroupSlice from './slices/GroupSlices'
import SystemCodeSlice from './slices/SystemCodeSlices'
import WorkflowSlice from './slices/WorkflowSlices'
import CustomSettingSlice from './slices/CustomSettingSlices'
import ModuleSlice from './slices/ModuleSlice'
import PermissionSlice from './slices/PermissionSlice'

import { DriverSlice } from './slices/DriverSlices'
import { VehicleSlice } from './slices/VehicleSlices'
import { TyreSlice } from './slices/TyreSlices'
import { TyreVendorSlice } from './slices/TyreVendorSlices'
import { VehicleTyreSlice } from './slices/VehicleTyreSlices'
import { ProgramSlice } from './slices/ProgramSlices'
import { TripSlice } from './slices/TripSlices'
import { ClientSlice } from './slices/ClientSlices'



export const store = configureStore({

  reducer: {
    organization: OrganizationSlice.reducer,
    organizationLocation: OrganizationLocationSlice.reducer,
    user: UserSlice.reducer,
    role: RoleSlice.reducer,
    group: GroupSlice.reducer,
    systemCode: SystemCodeSlice.reducer,
    workflow: WorkflowSlice.reducer,
    customSetting: CustomSettingSlice.reducer,
    module: ModuleSlice.reducer,
    permission: PermissionSlice.reducer,
    driver: DriverSlice.reducer,
    vehicle: VehicleSlice.reducer,
    tyre: TyreSlice.reducer,
    tyreVendor: TyreVendorSlice.reducer,
    vehicleTyre: VehicleTyreSlice.reducer,
    program: ProgramSlice.reducer,
    trip: TripSlice.reducer,
    client: ClientSlice.reducer,
  },
})
