import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {getDriverList} from '../../services/DriverService'
import {getDriverById} from '../../services/DriverService'
import {createDriver} from '../../services/DriverService'
import {updateDriver} from '../../services/DriverService'
import {deleteDriver} from '../../services/DriverService'
import {getAllDriverList} from '../../services/DriverService'


interface GetDriverListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown:number
}

interface DriverState {
  DriverList: any
  DriverData: any
  createDriverData: any
  updateDriverData: any
  AllDriverList: any
  // -------------------
}

const initialState: DriverState = {
  DriverList: null,
  DriverData: null,
  createDriverData: null,
  updateDriverData: null,
  AllDriverList: null,
  // -------------------

}

export const createDriverApi = createAsyncThunk(
  'DriverList/api/post',

  async (payload: any, {rejectWithValue}) => {
      const result = await createDriver(payload)
      console.log(result)
      if(result.data.success)
      return result.data
      else
      return rejectWithValue(result.data)
  }
)


export const getAllDriverListApi = createAsyncThunk(
  'AllDriverList/api/get',
  async ({}:  {}, 
    {rejectWithValue}
  ) => {
    try {
      const result = await getAllDriverList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



export const getDriverListApi = createAsyncThunk(
  'DriverList/api/get',
  async (
    {pageNumber, pageSize, filterkey,isDropdown}: GetDriverListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getDriverList(pageNumber, pageSize, filterkey,isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getDriverByIdApi = createAsyncThunk(
  'DriverById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getDriverById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateDriverApi = createAsyncThunk(
  'Driver/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    
      const result = await updateDriver(id, payload)
      if(result.data.success)
      return result.data
      else
      return rejectWithValue(result.data)
  }
)

export const deleteDriverApi = createAsyncThunk(
  'Driver/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteDriver(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)




export const DriverSlice: any = createSlice({
  name: 'Driver',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDriverListApi.fulfilled, (state, action) => {
        state.DriverList = action.payload
      })
      .addCase(getAllDriverListApi.fulfilled, (state, action) => {
        state.AllDriverList = action.payload
      })

      .addCase(getDriverByIdApi.fulfilled, (state, action) => {
        state.DriverData = action.payload
      })
      .addCase(createDriverApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createDriverData = action?.payload
      })
      .addCase(updateDriverApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateDriverData = action?.payload
      })
    
  },
})
export default DriverSlice.reducer
